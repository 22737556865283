let themeName = APP_THEME;
//import LoginPage from `../pages/${themeName}/login.vue`;
// import MenuPage from pagePath + '/menu.vue';
// import PlayPage from pagePath + '/play.vue';
// import ReceiptDetailPage from pagePath + '/receiptDetail.vue';
// import HistoryPage from pagePath + '/history.vue';
// import ReceiptListPage from pagePath + '/receiptList.vue';
// import BetDetailListPage from pagePath + '/betDetailList.vue';
// import ResultPage from pagePath + '/result.vue';
// import ResultListPage from pagePath + '/resultList.vue';

let appCheckLogin = function ({to, from, app, resolve, reject}) {
	
	//console.log('in appCheckLogin for to:');
	//console.log(to);
	// If JWT provided in URL query, use it
	if(to.query.jwt){
		app.store.state.jwtValue = to.query.jwt;
		app.store.state.userId = null;
		if(typeof(Storage) !== "undefined"){
			sessionStorage.setItem("jwtValue", to.query.jwt);
			sessionStorage.removeItem("userId");
		}
	}
	let jwtValue = app.store.state.jwtValue;

	if(!jwtValue){
		//console.log('in appCheckLogin:reject');
		reject();
		this.navigate('/login', {
			reloadAll: true
			// history: false,
			// browserHistory: false
		});
	} else {
		resolve();
	}
}


var routes = [
  
  {
    path: '/login',
    //component: LoginPage,
	asyncComponent: () => import(`../pages/${themeName}/login.vue`),
  },
  {
	path: '/menu',
	asyncComponent: () => import(`../pages/${themeName}/menu.vue`),
	beforeEnter: appCheckLogin
  },

  {
    path: '/',
	//component: PlayPage,
	asyncComponent: () => import(`../pages/${themeName}/play.vue`),
	options: {
		props: {
		  gameType: '4d',
		},
	},
	beforeEnter: appCheckLogin
  },
  {
    path: '/play/3d',
	asyncComponent: () => import(`../pages/${themeName}/play.vue`),
	options: {
		props: {
		  gameType: '3d',
		},
	},
	beforeEnter: appCheckLogin
  },
  {
    path: '/play/5d',
	asyncComponent: () => import(`../pages/${themeName}/play.vue`),
	options: {
		props: {
		  gameType: '5d',
		},
	},
	beforeEnter: appCheckLogin
  },
  {
    path: '/play/6d',
	asyncComponent: () => import(`../pages/${themeName}/play.vue`),
	options: {
		props: {
		  gameType: '6d',
		},
	},
	beforeEnter: appCheckLogin
  },
  {
	path: '/receipt',
	asyncComponent: () => import(`../pages/${themeName}/receiptDetail.vue`),
	options: {
	  props: {
		rspReceipt: '',
		confNo: '',
		betStatus: 'failed'
	  },
	},
  },

  {
    path: '/history',
	asyncComponent: () => import(`../pages/${themeName}/history.vue`),
	beforeEnter: appCheckLogin
  },
  {
	path: '/receipt-list',
	asyncComponent: () => import(`../pages/${themeName}/receiptList.vue`),
	beforeEnter: appCheckLogin,
	options: {
	  props: {
		params: {}
	  },
	},
  },
  {
	path: '/bet-detail-list',
	asyncComponent: () => import(`../pages/${themeName}/betDetailList.vue`),
	beforeEnter: appCheckLogin
  },
  {
    path: '/result',
	asyncComponent: () => import(`../pages/${themeName}/result.vue`),
	beforeEnter: appCheckLogin
  },
  {
	path: '/result-list',
	asyncComponent: () => import(`../pages/${themeName}/resultList.vue`),
	beforeEnter: appCheckLogin,
	options: {
	  props: {
		params: {}
	  },
	},
  },


];

export default routes;
