import { f7 } from 'framework7-vue';

function logoutUser() {
	f7.store.dispatch('destroyJwtValue');
	f7.store.dispatch('destroyUserId');
	if(typeof(Storage) !== "undefined"){
		sessionStorage.removeItem("jwtValue");
		sessionStorage.removeItem("userId");
	}

	f7.panel.close();
	f7.views.main.router.navigate('/login', {
		// history: false,
		// browserHistory: false
		reloadAll: true
	});
}

export {logoutUser}