<template>
  <f7-app v-bind="f7params" theme-dark>

	<!-- Your main view, should have "view-main" class -->
	<f7-view :main="true" :init="true"
			:browser-history="true"
			browser-history-separator=""
			:browser-history-root="appSubDirectory"
			id="mainView" class="safe-areas main-view" ></f7-view>

	<f7-panel right>
      <f7-view>
        <f7-page>
          <f7-block-title class="text-color-primary">Menu</f7-block-title>

          <f7-list>
			<f7-list-item link="/menu" view=".main-view" panel-close title="MAIN MENU" after="">
              <f7-icon slot="media" material="dashboard"></f7-icon>
            </f7-list-item>
            <f7-list-item link="/" view=".main-view" panel-close title="Play 4D" after="">
              <f7-icon slot="media" material="input"></f7-icon>
            </f7-list-item>
			<f7-list-item link="/play/3d" view=".main-view" panel-close title="Play 3D" after="">
              <f7-icon slot="media" material="input"></f7-icon>
            </f7-list-item>
			<f7-list-item link="/play/5d" view=".main-view" panel-close title="Play 5D" after="">
              <f7-icon slot="media" material="input"></f7-icon>
            </f7-list-item>
			<f7-list-item link="/play/6d" view=".main-view" panel-close title="Play 6D" after="">
              <f7-icon slot="media" material="input"></f7-icon>
            </f7-list-item>
			<f7-list-item link="/history" view=".main-view" panel-close title="Bet Receipt" after="">
              <f7-icon slot="media" material="history"></f7-icon>
            </f7-list-item>
			<f7-list-item link="/bet-detail-list" view=".main-view" panel-close title="Bet Detail" after="">
              <f7-icon slot="media" material="list"></f7-icon>
            </f7-list-item>
			<f7-list-item link="/result" view=".main-view" panel-close title="Draw Result" after="">
              <f7-icon slot="media" material="assignment"></f7-icon>
            </f7-list-item>
			<f7-list-item v-if="supportTopup" @click="openTopup" view=".main-view" panel-close title="Topup" after="">
              <f7-icon slot="media" material="attach_money"></f7-icon>
            </f7-list-item>
			<f7-list-item v-if="supportTopup" @click="openWithdraw" view=".main-view" panel-close title="Withdraw" after="">
              <f7-icon slot="media" material="local_atm"></f7-icon>
            </f7-list-item>
			<f7-list-item @click="doLogout" view=".main-view" panel-close title="Logout" after="">
              <f7-icon slot="media" f7="escape"></f7-icon>
            </f7-list-item>
          </f7-list>
        </f7-page>
      </f7-view>
    </f7-panel>
  </f7-app>
</template>
<script>
  import { ref, onMounted } from 'vue';
  import { f7, f7ready } from 'framework7-vue';

  import routes from '../js/routes.js';
  import store from '../js/store';
  import {openTopupUrl, openWithdrawUrl} from '../js/functions/topupWithdraw.js';
  import {logoutUser} from '../js/functions/logout.js';

  export default {
    setup() {

	  const appSubDirectory = APP_SUB_DIRECTORY;
      // Framework7 Parameters
      const f7params = {
        name: 'Play App', // App name
        theme: 'auto', // Automatic theme detection

        // App store
        store: store,
        // App routes
        routes: routes,
        // Register service worker
        serviceWorker: {
          path: `${appSubDirectory}/service-worker.js`,
		  scope: appSubDirectory.length > 0 ? appSubDirectory : '/'
        },
      };

	  const supportTopup = store.state.supportTopup;
	  const openTopup = function() {
		openTopupUrl(store.state.userId);
	  };
	  const openWithdraw = function() {
		openWithdrawUrl(store.state.userId);
	  };

	  const doLogout = logoutUser;

      onMounted(() => {
        f7ready(() => {
			
			console.log('f7ready:now');
			if(typeof(Storage) !== "undefined"){
				f7.store.state.jwtValue = sessionStorage.getItem("jwtValue");
				f7.store.state.userId = sessionStorage.getItem("userId");
			}
			//f7.views.main.router.refreshPage();
			// f7.views.main.router.navigate('/', {
			// 	reloadCurrent: true,
			// 	ignoreCache: true,
			// });
			
          // Call F7 APIs here
        });
      });

      return {
        f7params, appSubDirectory,
		supportTopup,
		openTopup, openWithdraw,
		doLogout
      }
    }
  }
</script>