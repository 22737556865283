function openTopupUrl(userId) {
	if(!APP_SUPPORT_TOPUP){
		 return;
	}
	if(userId){
		window.open(TOPUP_URL + userId, '_blank');
	}
}

function openWithdrawUrl(userId) {
	if(!APP_SUPPORT_TOPUP){
		 return;
	}

	if(userId){
		window.open(WITHDRAW_URL + userId, '_blank');
	}
}

export {
	openTopupUrl,
	openWithdrawUrl
}