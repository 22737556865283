
import { createStore } from 'framework7/lite';

const store = createStore({
  state: {
	jwtValue: null,
	userId: null,
	supportTopup: (typeof APP_SUPPORT_TOPUP == 'undefined') ? false : APP_SUPPORT_TOPUP,
  },
  getters: {
	jwtValue({state}) {
		return state.jwtValue;
	},
	userId({state}){
		return state.userId;
	},
	supportTopup({state}){
		return state.supportTopup
	}
  },
  actions: {
	storeJwtValue({state}, jwt) {
		state.jwtValue = jwt;
	},
	destroyJwtValue({state}) {
		state.jwtValue = null;
	},
	destroyUserId({state}) {
		state.userId = null;
	},

    addProduct({ state }, product) {
      state.products = [...state.products, product];
    },
  },
})
export default store;
